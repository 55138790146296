export default function initMenu(menuModules, actionPanel, subMenu) {
  if (!menuModules) {
    return;
  }
  console.log("ted init Menu")
  this.dsAdd("set","menu/groups",[]);
  menuModules.forEach((group, groupIndex) => {
    this.dsAdd(
      "add",
      "menu/groups",
      { name: group.label, icon: group.icon },
      "mainMenuGroup"
    );
    if (!group || !group.buttons) {
      return;
    }
    group.buttons.forEach((button, buttonIndex) => {
      var active = false;
      if (module === button) {
        this.dsAdd("set", "menu/activeIndex", buttonIndex);
        active = true;
      }
      this.dsAdd(
        "set",
        "menu/groups/" + groupIndex + "/buttons/" + buttonIndex,
        { name: button, active },
        "menuButton"
      );
      // _____________________ SUB MENU
        if(subMenu[button] != undefined){
          const subData = subMenu[button];
          this.dsAdd("set","menu/groups/"+groupIndex+"/buttons/"+buttonIndex+"/subMenuLines",subData);
        }
      // _____________________
    });
  });
  const openGroups =
    this.getStorage("menuGroups") || Array(menuModules.length).fill(false);
  this.dsAdd("set", "menu/openGroups", openGroups);
  actionPanel.forEach((panel, index) => {
    this.dsAdd(
      "set",
      "actionPanel/buttons/" + index,
      panel,
      "actionPanelButton"
    );
  });
}
