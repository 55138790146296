import React from "react";
import PureComponent from "../pure";
import sAction from "sAction";

export default class Custom extends PureComponent {
  constructor(props) {
    super(props);

  }

  render() {

    const data = this.props.data;
    let content = data && data.get ? data.get("content") : "";

    return (
      <div>
        <div id="loginPage">
          <div id="loginContentContainer">
            <div id="loginContainer">
              <div className="loginContent">
                {content}
              </div>
            </div>
            <div className="loginPageCreatedBy">
              <div className="version" />
              <div className="createdBy">{sAction.translate("LBL_LOGIN_CREATED_BY")}<img src={sAction.param.acmarkLogo} /></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}