import {Record} from "immutable";

export default function accCreateNewPortalUser(params) {
    let subpanelKey = this.dataGet(params.prefix + '/customData/portalUsersSubpanelID');
    let rel = this.dataGet(params.prefix + '/subpanels/' + subpanelKey + '/def/rel');
    let data = {
        module: 'acm_portal_users',
        parentId: params.id,
        parentModule: params.module,
        parentName: params.name,
        prefix: params.prefix,
        rel: rel
    };

    this.newSubRecord(data);
}
