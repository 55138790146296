import React from "react";
import sAction from "sAction";
import Loader from "ROOT/src/components/loader";


export default class DownloadXMLFeedPopup extends React.Component {
  _isMounted = false;
  
  constructor(props) {
    super(props);
    
    this.state = {
      token: "",
      loaded: false
    }
  }
  
  componentDidMount() {
    this._isMounted = true;
    
    sAction.rest.post("clientCustomAction", {action: "get_xml_feed_token"}, data => {
      if (!this._isMounted) return;
      
      if (data.token) {
        this.setState({token: data.token});
      }
      this.setState({loaded: true});
    });
  }
  
  componentWillUnmount() {
    this._isMounted = false;
  }
  
  copy(e) {
    var copyTextarea = e.target.parentElement.querySelector('input');
    copyTextarea.focus();
    copyTextarea.select();
  
    try {
      document.execCommand('copy');
    } catch (err) {
    
    }
    
    setTimeout(() => {
      copyTextarea.blur();
    }, 1000)
  }
  
  render() {
    if (!this.state.loaded) {
      return <Loader />
    }
    
    return (
      <>
        <div className="acmPopupHeader">Stažení XML feedu</div>
        <div className="icon-Close popupCloseIcon"></div>
        
        <div id="xmlfeed">
          <p><a onClick={() => sAction.downloadDocument({id: 2, module: "xml_feed", type: "downloadXMLfeed"})}>Stáhnout návod</a></p>
          <p><a onClick={() => sAction.downloadDocument({id: 1, module: "xml_feed", type: "downloadXMLfeed"})}>Stáhnout XML feed</a></p>
          {this.state.token && <><p><a
            onClick={() => sAction.downloadDocument({id: 3, module: "xml_feed", type: "downloadXMLfeed"})}>
            Stáhnout XML feed skladových zásob</a></p>
            <br/>
            <p>Skladové zásoby - API:</p>
            <input type="text" style={{width: 542}}
                   defaultValue={"https://baumitportal.cz/?xmlfeed=" + this.state.token} /> {'\u00A0'}
            <a onClick={this.copy}>Zkopírovat</a></>
          }
        </div>
      </>
    );
  }
}