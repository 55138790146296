export default function survey() {
  // anketa
  if (sessionStorage.getItem("loggedIn")) {
    sessionStorage.removeItem("loggedIn");
    
    // kontrola, jestli uzivatelovi zobrazit anketu
    let checkSurveyAnswers = () => {
      if (this.dataGet("conf/load")) {
        setTimeout(checkSurveyAnswers, 2500);
        return;
      }
      
      this.rest.post("checkSurveyAnswers", {}, (data) => {
        if (!data || !data.status) {
          setTimeout(checkSurveyAnswers, 2500);
          return;
        }
        
        if (data.show_survey) {
          sAction.popup("survey", {header: "Anketa"});
        }
      });
    }
  
    setTimeout(checkSurveyAnswers, 2500);
  }
}