import React from "react";

export default function routeInvitation(url) {
  this.load();

  this.rest.get("trns?language=" + this.getLanguage(), data => {

    clearInterval(this.notificationInterval);
    this.app_strings = data.lang;
    let urlNew = "#detail/" + url.module + "/" + url.record;

    this.rest.post(url.action, url, (data) => {
      let content;
      if (data && data.status) {
        content = (<React.Fragment><p><b>{this.translate("LBL_STATUS_UPDATED")}</b></p>
          <p>{this.translate("LBL_STATUS")} {this.app_strings.dom_meeting_accept_status[url.accept_status]}</p>
          <p><a href={urlNew}>{this.translate("LBL_MEETING_GO_BACK")}</a></p>
          <p/>
        </React.Fragment>);
      } else {
        content = data.errorMessage || "Error";
      }

      this.dsClear();
      this.dsAdd("set", "menu/groups", []);
      this.dsAdd("set", "conf/load", false);
      this.dsAdd("set", "conf/view", "custom");
      this.dsAdd("set", "view", { content });
      this.dsProcess();
      this.renderReact();

      if (this.rest.getCookie("sID")) {
        this.rest.get("checksid", data => {
          this.initData(data);
          this.dsProcess();
        });
      }

      setTimeout(() => this.hashChangeEvent(), 1000);

    });
  });
}