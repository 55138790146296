import React, { Component } from "react";
import PureComponent from "../../pure";
import Select from '../../formElements/Select'

import  sAction  from "sAction";

class PortalRoleAccessComp extends PureComponent {

    /*constructor(props){
        super(props);
        
        this.state = {
            //data : this.props.data.toJS()
        }

    }*/

    updateAccess(cat, type, val){
        sAction.dataSet(this.props.way+"/data/"+cat+"/"+type, val);
    }

    render() {
        const custData = this.props.data.toJS()
        const data = custData["data"];
      
        
        

        var tableHead = [];
        var tableBody = [];

        const category_opt = sAction.app_strings.acm_roles_actions_category_dom;
        const type_opt = sAction.app_strings.acm_roles_actions_type_dom;
        const access_dom = sAction.app_strings.acm_roles_actions_access_dom;

        var access_opt = [];

        access_dom.forEachObject((lbl, key) => {
            access_opt.push({value: key, label: lbl});
        });


        var trPom = [<th key={"head_first"}></th>];

        type_opt.forEachObject((type, key) => {
            if(key){
                trPom.push(<th key={"head_"+key}>{type}</th>);
            }
            
        });

        tableHead = <tr>{trPom}</tr>;

        let trCnt = 0;
        category_opt.forEachObject((category, keyC) => {
            if(keyC){
                trPom = [<td key={keyC+"_first"}>{category}</td>];
                type_opt.forEachObject((type, key) => {
                    if(key){
                        let val = "";
                        if(data[keyC]){
                            if(data[keyC][key]){
                                val = data[keyC][key];                     
                            }
                        }

                        let tdClass = "";
                        if(val == "0"){
                            tdClass = "noaccess";
                        }
                        else if(val == "99"){
                            tdClass = "accessall";
                        }

                        trPom.push(<td className={tdClass} key={keyC+"_"+key}>
                            <Select 
                                open={false} 
                                name={keyC+"_"+key}
                                options={access_opt} 
                                defaultValue={val} 
                                onChange={(ev) => this.updateAccess(keyC, key, ev.target.value)} />
                        </td>);
                    }
                    
                });
                let custClass = trCnt % 2 == 0 ? "evenRow" : "";
                trCnt++;
                tableBody.push(<tr className={custClass} key={"tr_"+keyC}>{trPom}</tr>);     
            }
        });



        return (
            <div className="detailCard portalAccessContainer">
                <table id="portalAccessTable">
                    <thead>
                        {tableHead}
                    </thead>
                    <tbody>
                        {tableBody}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default PortalRoleAccessComp;