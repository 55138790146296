
export default function loginAsPortal(params){
    
    const data = {
        id: params.id
    }

    this.rest.post("loginAsPortal",data,returnData => {
        if(returnData.status){
            this.rest.setCookie("sID", returnData.sid, 1);
            window.location.reload();
        }else{
            this.alert(this.translate("LBL_ERROR"));
        }
    })
    
}