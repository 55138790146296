export default { 
    fields: [
        {
            name: "name",
            type: "relateProd",
            lbl: "LBL_LIST_PRODUCT_NAME",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 30
        },
        {
            name: "mft_part_num_int",
            type: "text",
            lbl: "LBL_MFT_PART_NUM_INT",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 15
        },
        {
            name: "valid_to",
            type: "text",
            lbl: "LBL_LIST_VALID_TO",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 10
        },
        {
            name: "pack_size",
            type: "number",
            lbl: "LBL_LIST_PACK_SIZE",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 10
        },
        {
            name: "pack_mj",
            type: "text",
            lbl: "",
            visible: true,
            disabled: true,
            width: 5
        },
        {
            name: "discount_price",
            type: "price",
            lbl: "LBL_LIST_DISCOUNT_PRICE",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 10
        },
        {
            name: "total_discount_price",
            type: "price",
            lbl: "LBL_TOTAL_DISCOUNT_PRICE",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 15
        },
    ],
    sums : [
        {
            type: "addBtn",
            visible: true,
            width: "41%",
        },
        {
            type: "space",
            visible: true,
            width: "20px",
        },
        {
            type: "space",
            visible: true,
            width: "15%",
        },
        {
            name: "total_discount_price",
            type: "price",
            lbl: "LBL_NEW_SUB",
            lblMod: "Quotes",
            visible: true,
            width: "30%"
        }
    ]
};
