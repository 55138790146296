export default function downloadFile(data) {
  if(!data.id){
    console.error("Chybí id souboru/záznamu");
    // return; //vyjimka email
  }
  if(!data.module){
    console.error("Chybí modul souboru/záznamu");
    // return; //vyjimka email
  }
  this.popup("fileDownload",{state:"load"});

  this.filesXhr({
    action: "downloadFileRequest",
    data:data,
    success: ret => {
      if(ret.status == true){
        this.dsClear();
        const id = this.dataGet("conf/popup2/show") ? "2" : "";
        this.dsAdd("set","conf/popup"+id+"/data/state","init")
        this.dsAdd("set","conf/popup"+id+"/data/token",ret.token)
        this.dsProcess();
      }
      else {
        this.popupHide();

        if(ret.errMsg){
          this.error(ret.errMsg);
        }
        else{
          this.error("Omlouváme se, nastala neočekávaná chyba");
        }
      }
    }
  })
}
