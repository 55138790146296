export default function exportList(params) {
  const list = this.dataGet(params.prefix);
  var selected = list.selected;
  if (typeof selected == "object") {
    selected = selected.toJS();
  }
  if (!selected || !selected.length) {
    this.error("Nevybrali jste žádné záznamy - nelze pokračovat.");
    return;
  }
  const savedSearch = list.actSavedSearch;
  const exportData = this.reloadListParams({
    list,
    prefix: params.prefix,
    savedSearch,
    columns: true
  });
  exportData["selected"] = selected;
  exportData["module"] = list.modul;
  exportData["type"] = list.type;

  var defs = {};
  list.rows.forEach(row => {
    const def = row.def.toJS();
    defs[def["fieldModule"] + "." + def["name"]] = def;
  });

  var popupColumns = [];
  exportData.columns.forEach(column => {
    const type = defs[column.fModule + "." + column.fName]["type"];
    const vname = defs[column.fModule + "." + column.fName]["vname"];
    const translate = this.translate(vname,column.module);
    popupColumns.push({
      name: column.field,
      def: {
          fieldModule:defs[column.fModule + "." + column.fName]["fieldModule"],
          type:type,
          vname: vname,
          
      }
    });
    defs[column.fModule + "." + column.fName]["translate"] = translate.replace(":","");
  });

  this.popup("exportList", {
    header: this.translate("LBL_EXPORT_LIST"),
    columns: popupColumns,
    type: params.type,
    callback: data => {

      var formData = this.objectToFormdata(exportData, false, "filter");
      formData = this.objectToFormdata(defs, formData, "def");
      formData.append("module", list.modul);
      formData.append("exportType",data["type"]);
      formData.append("addId",data["addId"]);
      formData = this.objectToFormdata(data["totalRow"],formData,"totalRow");


      
      formData = {
        filter: exportData,
        module: list.modul,
        exportType: data["type"],
        addId: data["addId"],
        totalRow: data["totalRow"],
        action:"listviewExport",
        name: data['name'],
        type: data['type'],
      }

      this.dsClear();
      this.dsAdd("set","conf/popup/content","fileDownload");
      this.dsAdd("set","conf/popup/data/state","load");
      this.dsProcess();

      this.filesXhr({
        action: "generateFileRequest",
        data: formData,
        success: ret => {
          if(ret && ret.status == true){
            this.dsClear();
            this.dsAdd("set","conf/popup/data/state","init")
            this.dsAdd("set","conf/popup/data/token",ret.token)
            this.dsProcess();

            if (ret.not_found && ret.not_found.length) {
              this.error("Pozor, některé soubory nenalezeny: <br><br>" + ret.not_found.join("<br>"));
            }
          } else {
            this.popupHide();

            if(ret.errMsg){
              this.error(ret.errMsg);
            }
            else{
              this.error("Omlouváme se, nastala neočekávaná chyba");
            }
          }
        }
      });
    }
  });
}
