
import React, { Component } from 'react'

import InputField from '../inputFieldClass'
import  sAction  from "sAction";
export default class SingleEmail extends InputField {
  constructor(){
    console.log('asdasdasdasd');
    super();
    this.state = {
      state: "fine",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      errMsg: sAction.translate("LBL_INVALID_USER_NAME_EMAIL", "acm_portal_users"),
    };
  }
}
