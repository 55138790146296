export default function deactivatePortalAccount(params) {
    this.confrim(this.translate('LBL_CONFIRM_DEACTIVATE_ACCOUNT', 'Accounts'), () => {
        this.popupHide();
        this.load();
        this.rest.post('deactivatePortalAccount', {accountID: params.id}, returnData => {
            this.unLoad();
            if (returnData.status === true) {
                // Vse ok, vypis zpravu
                const data = {
                    header: 'Info',
                    buttons: [{ label: "OK", callback: () => this.popupHide() }],
                };

                this.popup(this.translate(returnData.message.text), data);
            } else {
                // chyba, vypis errorMessage
                this.error(this.translate(returnData.errorMessage.text));
            }
        });
    });
}
