import React from "react";
import Button from "../../formElements/Button";
import TickBox from '../../formElements/TickBox'

import  sAction  from "sAction";


export default class PortalAdminMassAssignUsers extends React.Component {
    ignoredRoles = [];
    ignoredBranches = [];

    constructor(props){
        super(props);
        const data = props.data.toJS();

        this.state = {
            roles : data.data.roles,
            branches : data.data.branches,
            selRoles : [],
            selBranches : [],
            callback: data.data.callback,
        };

    }

    handleSave(){
        let roles = this.state.selRoles;
        let branches = this.state.selBranches;

        if(roles.length == 0 || branches.length == 0){
            sAction.error(sAction.translate("LBL_ERROR_NO_BRANCH_ROLE_SELECTED", "acm_portal_users"));
            return;
        }

        this.state.callback({
            roles,
            branches,
        });

        sAction.popupHide();

    }

    selectItem(type, id, val){
        let items = this.state[type];

        

        if(val){
            if(!items.includes(id)){
                items.push(id);
            }
        }
        else{
            if(items.includes(id)){
                items = items.filter(function(it) { return it !== id });
            }
        }

        let pomPar = {};
        pomPar[type] = items;
        this.setState(pomPar);
    }

    massSelectItem(type, val){
        let items = this.state[type];

        let options = [];
        let ignored = [];

        if(type == "selBranches"){
            options = this.state.branches.map(it => it.id);
            ignored = this.ignoredBranches;
        }
        else if(type = "selRoles"){
            options = this.state.roles.map(it => it.id);
            ignored = this.ignoredRoles;
        }

        let pomPar = {};
        if(val){
            options.forEach((id) => {
                if(!items.includes(id) && !ignored.includes(id))
                items.push(id);
            })
        }
        else{
            let newItems = [];
            items.forEach((id) => {
                if(ignored.includes(id)){
                    newItems.push(id);
                }
                
            })
            items = newItems;
        }
        pomPar[type] = items;
        this.setState(pomPar);
    }

    searchBranch(val){
        this.ignoredBranches = [];
        this.state.branches.forEachObject((acc) => {
            if(!acc.name.toLowerCase().includes(val.toLowerCase())){
                document.getElementById("mu"+acc.id).style.display="none";
                this.ignoredBranches.push(acc.id);
            }
            else{
                document.getElementById("mu"+acc.id).style.display="block";
            }
        })
    }

    searchRole(val){
        this.ignoredRoles = [];
        this.state.roles.forEachObject((role) => {
            if(!role.name.toLowerCase().includes(val.toLowerCase())){
                document.getElementById("mu"+role.id).style.display="none";
                this.ignoredRoles.push(role.id);
            }
            else{
                document.getElementById("mu"+role.id).style.display="block";
            }
        })
    }

    render(){

        let roleItems = [];
        let branchItems = [];

        this.state.roles.forEachObject((role, key )=> {
            let checked = false;
            let items = this.state.selRoles;
            if(items.includes(role.id)){
                checked = true
            }
            roleItems.push(
                <div key={role.id} id={"mu"+role.id}>
                    <TickBox 
                        className="portalAdminTick"  
                        onChange={(value) => this.selectItem("selRoles", role.id, value)}
                        checked={checked}
                    />
                    {role.name}
                </div>
            );
        });

        this.state.branches.forEachObject((branch, key )=> {
            let checked = false;
            let items = this.state.selBranches;
            if(items.includes(branch.id)){
                checked = true
            }
            branchItems.push(
                <div key={branch.id} id={"mu"+branch.id}>
                    <TickBox 
                        className="portalAdminTick"  
                        onChange={(value) => this.selectItem("selBranches", branch.id, value)}
                        checked={checked}
                    />
                    {branch.name}
                </div>
            );
        });

        return <React.Fragment>
        <div className="viewActionPanelButtons">
            <Button className="hoverGreen" onClick={() => this.handleSave()}>
                <div className={"icon-saveIcon calHeaderIcon"} ></div>
                {sAction.translate("LBL_SAVE", "Home")}
            </Button>
            <Button className="hoverRed" onClick={() => sAction.popupHide()}>
                <div className={"icon-close calHeaderIcon"} ></div>
                {sAction.translate("LBL_CANCEL_ACTION")}
            </Button>
        </div>
            <div className="portalAdminMassUpdContainer">
                <div className="portalAdminMassUpdItems">
                    <div className="search adminTreeSearch">
                    <input 
                        type="text" 
                        className="search" 
                        placeholder={sAction.translate("LBL_SEARCH")+"..."}
                        onChange={(ev) => this.searchRole(ev.target.value)}        
                        />
                    </div>
                    <div className="portalAdminMassUpdHeader">
                        <TickBox 
                            className="portalAdminTick portalAdminMassTick"  
                            onChange={(value) => this.massSelectItem("selRoles", value)}
                        />
                        {sAction.translate("LBL_ROLES", "acm_portal_users")}
                    </div>
                        
                    <div className="portalAdminMassUpdItemsContainer">
                        {roleItems}
                    </div>
                </div>
                <div className="portalAdminMassUpdItems">
                    <div className="search adminTreeSearch">
                    <input 
                        type="text" 
                        className="search" 
                        placeholder={sAction.translate("LBL_SEARCH")+"..."}
                        onChange={(ev) => this.searchBranch(ev.target.value)}    
                        />
                    </div>
                    <div className="portalAdminMassUpdHeader">
                        <TickBox 
                            className="portalAdminTick portalAdminMassTick"  
                            onChange={(value) => this.massSelectItem("selBranches", value)}
                        />
                        {sAction.translate("LBL_BRANCHES", "acm_portal_users")}
                    </div>
                    <div className="portalAdminMassUpdItemsContainer">
                        {branchItems}
                    </div>
                </div>
                
                
            </div>
        </React.Fragment>;
    }
}