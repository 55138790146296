import React from "react";
import sAction from "sAction";
import Button from "../../formElements/Button";

export default class Survey extends React.PureComponent {
  constructor(props) {
    super(props);
    
    this.state = {
      referer: "",
      orientation: "",
      usage: "",
      feedback: "",
      tshirt_size: "",
      
      name: "",
      street: "",
      descriptive_number: "",
      city: "",
      zip_code: "",
      note: "",
    }
    
  }
  
  handleSave = () => {
    if (!this.state.referer
      || !this.state.orientation
      || !this.state.usage
      || !this.state.tshirt_size
      || (this.state.tshirt_size && this.state.tshirt_size !== "Žádné" && (!this.state.street || !this.state.city || !this.state.zip_code)))
    {
      sAction.error("Nejsou vyplněny některé údaje")
      return;
    }
  
    sAction.load();
    sAction.rest.post("saveSurveyAnswers", this.state, (data) => {
      sAction.unLoad();
      if (!data || !data.status || !data.success) {
        sAction.error("Anketu se nepodařilo odeslat.");
        return;
      }
      
      sAction.popupHide();
      sAction.alert("Anketa byla úspěšně odeslána. Děkujeme.");
    });
  }
  
  handleChange = ({target: {name, value}}) => {
    this.setState({[name]: value}, () => {
      console.log(this.state);
    });
  };
  
  isFormValid = () => {
    const {
      ie_name,
    } = this.state;
    
    const errors = [];
    const error_msg = [];
    if (!ie_name) errors.push("ie_name");
    
    this.setState({errors});
    
    if (errors.length) {
      // Nejsou vyplněny všechny povinné položky
      this.setState({error_msg: [sAction.translate("ERR_FILL_REQUIRED_ITEMS", "Emails")]});
      return false;
    }
    
    return true;
  };
  
  render() {
    return (<div className="survey">
      
      <p>Vážení obchodní přátelé,
        <br/><br/>
        děkujeme, že využíváte Baumit portál. Níže je pro Vás připravena krátká anketa. Jejím vyplněním nám pomůžete
        získat jednoduchou, ale důležitou zpětnou vazbu přímo od Vás, uživatelů. Jako poděkování za vyplnění Vám rádi
        zašleme tričko. Typ a velikost si vyberte níže.
        <br/><br/>
        Tým Baumit</p>
      <br/>
      
      <ol>
        <li>Jak jste se o Baumit Portálu dozvěděl/a?
          <ul className="no-bullets">
            <li><input onChange={this.handleChange} id="a1" type="radio" name="referer" value="Z informačního e-mailu"/>
              <label htmlFor="a1">Z informačního e-mailu</label></li>
            <li><input onChange={this.handleChange} id="a2" type="radio" name="referer" value="Od obchodního zástupce"/>
              <label htmlFor="a2">Od obchodního zástupce</label></li>
            <li><input onChange={this.handleChange} id="a3" type="radio" name="referer" value="Ze stránek Baumit.cz"/>
              <label htmlFor="a3">Ze stránek Baumit.cz</label></li>
            <li><input onChange={this.handleChange} id="a4" type="radio" name="referer" value="Z Baumit Magazínu"/>
              <label htmlFor="a4">Z Baumit Magazínu</label></li>
            <li><input onChange={this.handleChange} id="a5" type="radio" name="referer" value="Odjinud"/>
              <label htmlFor="a5">Odjinud</label></li>
          </ul>
        </li>
        
        <li>Je pro vás orientace na portálu jednoduchá?
          <ul className="no-bullets">
            
            <li><input onChange={this.handleChange} id="b1" type="radio" name="orientation" value="Ano"/>
              <label htmlFor="b1">Ano</label></li>
            <li><input onChange={this.handleChange} id="b2" type="radio" name="orientation" value="Chvíli mi trvá, než se zorientuji"/>
              <label htmlFor="b2">Chvíli mi trvá, než se zorientuji</label></li>
            <li><input onChange={this.handleChange} id="b3" type="radio" name="orientation" value="Ne, nikdy nemůžu najít, co potřebuji"/>
              <label htmlFor="b3">Ne, nikdy nemůžu najít, co potřebuji</label></li>
            <li><input onChange={this.handleChange} id="b4" type="radio" name="orientation" value="Jsem tu poprvé, ale vypadá to přehledně"/>
              <label htmlFor="b4">Jsem tu poprvé, ale vypadá to přehledně</label></li>
            <li><input onChange={this.handleChange} id="b5" type="radio" name="orientation" value="Jsem tu poprvé a nevypadá to zajímavě"/>
              <label htmlFor="b5">Jsem tu poprvé a nevypadá to zajímavě</label></li>
          </ul>
        </li>
        
        <li>Jak často vyhledáváte informace na portálu?
          <ul className="no-bullets">
            <li><input onChange={this.handleChange} id="c1" type="radio" name="usage" value="Každý den"/>
              <label htmlFor="c1">Každý den</label></li>
            <li><input onChange={this.handleChange} id="c2" type="radio" name="usage" value="Jednou týdně"/>
              <label htmlFor="c2">Jednou týdně</label></li>
            <li><input onChange={this.handleChange} id="c3" type="radio" name="usage" value="Jednou do měsíce"/>
              <label htmlFor="c3">Jednou do měsíce</label></li>
            <li><input onChange={this.handleChange} id="c4" type="radio" name="usage" value="Vůbec"/>
              <label htmlFor="c4">Vůbec</label></li>
          </ul>
        </li>
        
        <li>Co byste na Baumit Portálu vylepšili, jaké informace vám zde chybí?<br/>
          <textarea onChange={this.handleChange} name="feedback" style={{width: 350, height: 100, marginTop: 10}}></textarea>
        </li>
        
        <li>Budu chtít tričko:
          <table>
            <tr><td><input onChange={this.handleChange} id="d1" type="radio" name="tshirt_size" value="Dámské S"/>
              <label htmlFor="d1">Dámské S</label></td>
              
              <td><input onChange={this.handleChange} id="d6" type="radio" name="tshirt_size" value="Pánské S"/>
              <label htmlFor="d6">Pánské S</label></td>
            </tr>
            <tr><td><input onChange={this.handleChange} id="d2" type="radio" name="tshirt_size" value="Dámské M"/>
              <label htmlFor="d2">Dámské M</label></td>
              
              <td><input onChange={this.handleChange} id="d7" type="radio" name="tshirt_size" value="Pánské M"/>
              <label htmlFor="d7">Pánské M</label></td>
            </tr>
            <tr><td><input onChange={this.handleChange} id="d3" type="radio" name="tshirt_size" value="Dámské L"/>
              <label htmlFor="d3">Dámské L</label></td>
              
              <td><input onChange={this.handleChange} id="d8" type="radio" name="tshirt_size" value="Pánské L"/>
              <label htmlFor="d8">Pánské L</label></td>
            </tr>
            <tr><td><input onChange={this.handleChange} id="d4" type="radio" name="tshirt_size" value="Dámské XL"/>
              <label htmlFor="d4">Dámské XL</label></td>
              
              <td><input onChange={this.handleChange} id="d9" type="radio" name="tshirt_size" value="Pánské XL"/>
              <label htmlFor="d9">Pánské XL</label></td>
            </tr>
            <tr><td><input onChange={this.handleChange} id="d5" type="radio" name="tshirt_size" value="Dámské XXL"/>
              <label htmlFor="d5">Dámské XXL</label></td>
              
              <td><input onChange={this.handleChange} id="d10" type="radio" name="tshirt_size" value="Pánské XXL"/>
              <label htmlFor="d10">Pánské XXL</label></td>
            </tr>
            <tr><td><br /><input onChange={this.handleChange} id="d11" type="radio" name="tshirt_size" value="Žádné"/>
              <label htmlFor="d11">Žádné</label></td><td></td></tr>
          </table>
        </li>
  
        {this.state.tshirt_size !== "Žádné" && (
        <li>Tričko zaslat na adresu:<br/>
          <table style={{marginTop: 10}}>
            <tbody>
            <tr>
              <td><label htmlFor="e1">Jméno</label></td>
              <td><input onChange={this.handleChange} id="e1" type="text" name="name" value={this.state.name}/></td>
            </tr>
            <tr>
              <td><label htmlFor="e2">Ulice</label></td>
              <td><input onChange={this.handleChange} id="e2" type="text" name="street" value={this.state.street}/></td>
            </tr>
            <tr>
              <td><label htmlFor="e3">Číslo popisné</label></td>
              <td><input onChange={this.handleChange} id="e3" type="text" name="descriptive_number" value={this.state.descriptive_number}/></td>
            </tr>
            <tr>
              <td><label htmlFor="e4">Město</label></td>
              <td><input onChange={this.handleChange} id="e4" type="text" name="city" value={this.state.city}/></td>
            </tr>
            <tr>
              <td><label htmlFor="e5">PSČ</label></td>
              <td><input onChange={this.handleChange} id="e5" type="text" name="zip_code" value={this.state.zip_code}/></td>
            </tr>
            <tr>
              <td><label htmlFor="e6">Poznámka</label></td>
              <td><textarea id="e6" onChange={this.handleChange} name="note" style={{height: 50}}></textarea></td>
            </tr>
            </tbody>
          </table>
        
        </li>)}
        
      </ol>

      <br />
      <Button className="stillHover" onClick={this.handleSave}>
        <div className={"icon-saveIcon calHeaderIcon"} ></div>
        Odeslat
      </Button>
    </div>);
  }
}
