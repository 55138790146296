import React from "react";
import PureComponent from "../pure";

import MoreOptions from "../formElements/MoreOptions";
import TickBox from "../formElements/TickBox";
import  sAction  from "sAction";
import ListViewPagination from "./ListViewPagination"
export default class ListViewFooter extends PureComponent {

  addFilter(field, type, value) {
    //    this.props.parent.props.parent.filterClose();
    const prefix = this.props.prefix;
    const modul = this.props.module;
    if (
      modul == "Contacts" ||
      modul == "Leads" ||
      modul == "Prospects" ||
      modul == "Users"
    ) {
      field = "last_name";
    } else if (modul == "Documents") {
      field = "document_name";
    }
    var filter = {
      field: field,
      type: type,
      value: value,
      footer: true
    };
    var filters = new Array(filter);

    const data = {
      field: field,
      filters: filters,
      operator: "and",
      prefix: prefix,
      reload: true
    };
    if (value != "%%") {
      sAction.addFilter(data);
    } else {
      sAction.removeFilter(data);
    }
  }
  selectPage() {
    const prefix = this.props.prefix;
    sAction.toggleAll(true, prefix);
  }
  selectAll() {
    const prefix = this.props.prefix;
    sAction.dsClear();
    sAction.dsAdd("set", prefix + "/selected", "all");
    sAction.dsAdd("set", prefix + "/selectPage", true);
    sAction.dsProcess();
  }
  removeSelect() {
    const prefix = this.props.prefix;
    sAction.dsClear();
    sAction.dsAdd("set", prefix + "/selected", []);
    sAction.dsAdd("set", prefix + "/selectPage", false);
    sAction.dsProcess();
  }
  resetWidth(){
    const view = sAction.getViewName()
    const way = view + this.props.module
    let filterData = sAction.getStorage('listFilterData')
    if(filterData[way]['widthInfo']) {
      filterData[way]['widthInfo'] = []
      sAction.setStorage('listFilterData', filterData)
      sAction.afterLoadListView(this.props.prefix);
    }
  }

  render() {
    const module = this.props.module;
    const offset = this.props.offset;
    const rowCount = this.props.rowCount;
    var selected = this.props.selected;
    const selectedActive = this.props.selectedActive;
    const page = this.props.page;
    const filter = this.props.filter;

    if (selected == undefined) {
      selected = sAction.translate("LBL_ALL");
    }

    const countInfo = (
      <React.Fragment>
        {"|"}
        <TickBox
          checked={true}
          title={sAction.translate("LBL_SELECTED_RECORDS")}
        />
        {":" + selected}
      </React.Fragment>
    );
    var activeLetter = null;
    filter.forEach(field => {
      if (
        field.field == "name" ||
        field.field == "last_name" ||
        field.field == "document_name"
      ) {
        const filters = field.filters;
        filters.forEach(filter => {
          if (filter.type == "start") {
            activeLetter = filter.value;
          }
        });
      }
    });
    var filterField = "name";

    const alphabet = new Array(
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "CH",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z"
    );
    var alphabetRender = [];
    alphabet.forEach((letter, key) => {
      alphabetRender.push(
        <div
          onClick={() => this.addFilter(filterField, "start", letter)}
          key={key}
          className={activeLetter == letter ? "letter activeLetter" : "letter"}
        >
          <div>{letter}</div>
        </div>
      );
    });

    const type = this.props.type;
    var pomClass = "";
    if (type == "rightPanelQuotes") {
      pomClass = " rightPanelQuotes";
    }

    return (
      <div className="listViewFooter">
        {type != "reportWindow" && selectedActive == true && (
          <div className={"countInfoContainer footerBlock" + pomClass}>
            <div className="countInfo">
              <MoreOptions
                options={[
                  {
                    label: sAction.translate("LBL_SELECT_PAGE"),
                    icon:"icon-selectPage",
                    value: "selectPage"
                  },
                  {
                    label: sAction.translate("LBL_SELECT_ALL_RECORDS"),
                    icon:"icon-selectAll",
                    value: "selectAll"
                  },
                  {
                    label: sAction.translate("LBL_UNDO_ALL_SELECT"),
                    icon:"icon-selectUndo",
                    value: "removeSelect"
                  },
                  {
                    label: sAction.translate("LBL_RESET_COLUMN_WIDTH"),
                    icon: "icon-resetWidths",
                    value: "resetWidth"
                  }
                ]}
                onClick={(e, val) => this[val](e)}
              >
                <div className="icon-More listViewSelectMoreOptions" />
              </MoreOptions>
              {countInfo}
            </div>
          </div>
        )}
        {this.props.type != "rightPanelQuotes" && (
          <div className="alphabetContainer footerBlock">
            {type != "reportWindow" && !["acm_invoices", "acm_orders", "acm_delivery_notes"].includes(module) && (
              <div className="alphabet">
                <div
                  className={
                    activeLetter == null ? "letter activeLetter" : "letter"
                  }
                >
                  <div
                    onClick={() => this.addFilter(filterField, "cont", "%%")}
                  >
                    {sAction.translate("LBL_FOOTER_SEARCH_ALL")}
                  </div>
                </div>
                {alphabetRender}
              </div>
            )}
          </div>
        )}
        <ListViewPagination offset={offset} 
        limit={this.props.limit} 
        rowCount={rowCount}
        rowTotalCount={this.props.rowTotalCount}
        page={page}
        prefix={this.props.prefix}
        />
      </div>
    );
  }
}
