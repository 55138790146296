export default function portalUserSetAsAdmin(params){
    this.confrim(this.translate("LBL_SET_AS_ADMIN_CONFIRM", "acm_portal_users"), () => {
        this.popupHide();
        this.load();

        let paramData = {
            "action" : "portal_users_set_as_admin",
            "id" : params.id,
        }

        this.rest.post("clientCustomAction", paramData, (resp) => {
            this.unLoad();
            if (resp.errCode == "OK") {
                location.reload();
            } 
            else {
                
                this.error(resp.errMsg);
            }
        });
    })
}