import React from "react";
export default function FileName(props) {
  var lineRender = null;
    lineRender = <a title={props.value} href={"#detail/" + props.module + "/" + props.id}>{props.value}</a>;
 

  var className = "newListViewLine";
  if(props.extraClass !== undefined){
      className += " "+props.extraClass
  }

  let onClick = () => props.onGetFile({id:props.id,module:props.module})
  // vyjimka: subpanel potvrzeni objednavky
  if (props.module === "acm_acm_orders_confirms") {
    if (props.value === "____") {
      // kdyz nebyl pridan soubor, nic se nezobrazi
      return <div className={className}>-</div>;
    } else {
      // po kliku na nazev se soubor rovnou stahne
      let data = {
        id: props.id,
        module: props.module,
        type: "downloadDocument",
        cache: true,
        sActionFunction: "downloadDocument"
      }
      onClick = () => props.onGetFile(data);
      lineRender = <a title={props.value} onClick={onClick}>{props.value}</a>;
    }
  }

  return (
    <div className={className}><div onClick={onClick} className="icon-download listDownLoadIcon"></div>{lineRender}</div>
  );
}
