/*
http://localhost:8080/#custom/?action=acceptDecline&module=Calls&record=136201f1-945e-4bc2-8179-46248d0cb1f0&contact_id=969eb1e5-cb36-1b63-e89e-46248ccc391f&accept_status=accept
 */

import React from "react";

export default function routeCustom(hash = false) {
  let url = hash ? hash : window.location.hash;

  if (!/\?/.test(url)) return;

  url = url.replace(/.*\?/, "");

  url = JSON.parse(
    "{\"" + url.replace(/&/g, "\",\"").replace(/=/g, "\":\"") + "\"}",
    function(key, value) {
      return key === "" ? value : decodeURIComponent(value);
    }
  );

  switch (url.action) {
    case "acceptDecline":
      // prijmuti nebo odmitnuti hovoru nebo schuzky pres odkaz v e-mailu a presmerovani na dany zaznam...
      this.routeInvitation(url);
      break;
    case 'activatePortalUser':
    case 'resetPasswordPortalUser':
      this.load();

      this.rest.get("trns?language=" + this.getLanguage(), data => {

        clearInterval(this.notificationInterval);
        this.app_strings = data.lang;

        this.rest.post('activatePortalUser', url, (data) => {
          let content;
          if (data && data.status) {
            if(url.action == 'activatePortalUser'){
              content = (<React.Fragment><p style={{fontSize: '20px'}}><b>{this.translate('LBL_PORTAL_USER_ACTIVATED')}</b></p>
            </React.Fragment>);
            }
            else if(url.action == 'resetPasswordPortalUser'){
              content = (<React.Fragment><p style={{fontSize: '20px'}}><b>{this.translate('LBL_PORTAL_USER_PASSWORD_RESETED')}</b></p>
            </React.Fragment>);
              
            }
          } else {
            content = (<p style={{fontSize: '20px'}}><b>{this.translate('LBL_TOKEN_INVALID')}</b></p>);
          }

          this.dsClear();
          this.dsAdd("set", "menu/groups", []);
          this.dsAdd("set", "conf/load", false);
          this.dsAdd("set", "conf/view", "custom");
          this.dsAdd("set", "view", { content });
          this.dsProcess();
          this.renderReact();

          if (this.rest.getCookie("sID")) {
            this.rest.get("checksid", data => {
              this.initData(data);
              this.dsProcess();
            });
          }

          setTimeout(() => this.hashChangeEvent(), 1000);

        });
      });
      break;
  }
}
