export default function downloadDocument(data){
  data = {
    id: data.id,
    module: data.module,
    type: data.type || "downloadDocument",
    cache: true
  }

  this.popup("fileDownload",{state:"load"});

  this.filesXhr({
    action:"generateFileRequest",
    data:data,
    success: ret => {
      if(ret && ret.status == true){
        this.dsClear();
        const id = this.dataGet("conf/popup2/show") ? "2" : "";
        this.dsAdd("set","conf/popup"+id+"/data/state","init")
        this.dsAdd("set","conf/popup"+id+"/data/token",ret.token)
        this.dsProcess();
      } else {
        this.popupHide();

        if(ret.errMsg){
          this.error(ret.errMsg);
        }
        else{
          this.error("Omlouváme se, nastala neočekávaná chyba");
        }
      }
    }
  })
}