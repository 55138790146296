export default function switchPortalUser(params) {
    this.confrim(this.translate('LBL_CONFIRM_' + params.action.toUpperCase() + '_USER', 'acm_portal_users'), () => {
        this.popupHide();
        this.load();
        this.rest.post('switchPortalUser', params, returnData => {
            this.unLoad();
            if (returnData.status) {
            const data = {
                header: 'Info',
                buttons: [{ label: 'OK', callback: () => this.popupHide() }],
            };

            this.popup(this.translate(returnData.message.text), data);
            } else {
                this.error(this.translate(returnData.errorMessage.text));
            }
        });
    });
}
