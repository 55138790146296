import React from "react";
import sAction from "../../action"
import {width} from "../charts/_globalSettings"
// import PropTypes from "prop-types";

const MyNode = ({ nodeData }) => {
  const selectNode = () => {
    // alert(sAction.toJSON(nodeData));
  };

  // console.log(nodeData)
  // console.log(nodeData.contacts)
  var contactsArr = [];
  var assigned = [];

  var contacts = '';
  var counterContacts = 0;
  if (nodeData.contacts){
    if (Object.keys(nodeData.contacts).length){
      counterContacts ++;
      contactsArr.push(<div key={contactsArr}>{sAction.translate("LBL_CONTACT", "Accounts")}</div>)
      nodeData.contacts.forEach((c) => {
        contactsArr.push(<div key={c.id}><a href={c.url} target="_blank">{sAction.decodeHTMLEntities(c.first_name) + ' ' + sAction.decodeHTMLEntities(c.last_name)}</a></div>)
        contacts += c.first_name + ' ' + c.last_name + '<br>';
      })
    }
    else
    {
      counterContacts ++;
      contactsArr.push(<div key={'contactsArr'+counterContacts}>{sAction.translate("LBL_NO_CONTACTS", "Accounts")}</div>)
    }
  }
  else
  {
    counterContacts ++;
    contactsArr.push(<div key={'contactsArr'+counterContacts}>{sAction.translate("LBL_NO_CONTACTS", "Accounts")}</div>)
  }

  assigned.push(<div key={'assignedArr'}>{sAction.translate("LBL_ASSIGNED", "Accounts")}</div>)
  assigned.push(sAction.decodeHTMLEntities(nodeData.name))

  return (
    <div onClick={selectNode}>
      <div className="position"><a href={nodeData.acc_url} target="_blank">{sAction.decodeHTMLEntities(nodeData.title)}</a></div>
      <div className="fullname">{assigned}</div>
      <div className="fullname">{contactsArr}</div>
    </div>
  );
};

// const propTypes = {
//   nodeData: PropTypes.object.isRequired
// };

// MyNode.propTypes = propTypes;

export default MyNode;
