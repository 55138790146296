export default function routePortalAdmin(){

    this.rest.post("getAdminData", {}, (data) => {

        const prefix = "view";
        if(data.status){
            this.dsAdd('set', prefix, {});
            this.dsAdd('set', prefix + '/users', data.message.users);
            this.dsAdd('set', prefix + '/roles', data.message.roles);
            this.dsAdd('set', prefix + '/accounts', data.message.accounts);
            this.dsAdd('set', prefix + '/parent', data.message.parent);
            this.dsAdd('set', prefix + '/rolesPom', data.message.rolesPom);
            this.dsAdd('set', prefix + '/needSave', false);
        }

        this.dsAdd('set', 'conf/load', false);
        this.dsProcess();
    });
  }