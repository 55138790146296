export default function printPDFcheatsheet(params) {
  // acm_pdf_tahak
  if (typeof params.id === "string") {
    params.id = [params.id];
  } else {
    params.id = this.dataGet(params.prefix + "/selected");
    if (typeof params.id === "object") {
      params.id = params.id.toJS();
    }
  }
  if (!params.id.length) {
    this.error('Nelze pokračovat - nejprve vyberte nějaké položky.');
    return;
  }
  params.action = 'printPdf';
  this.downloadFile(params);
}
