export default function calcSums(way, params){
    var dph = params.data.dph;

    if(params.type == "group"){
        return calcGroupSums(params.data, this, dph);
    }

    var data = params.data;

    var total_cost_price = 0;
    var total_list_price = 0;
    var total_discount_amount = 0;
    var total_discount_price = 0;
    var total_tax = 0;

    var self = this;

    data.groups.forEach(function(group){
        let pom = calcGroupSums(group, self, dph);
        total_cost_price += pom.total_cost_price;
        total_list_price += pom.total_list_price;
        total_discount_amount += pom.total_discount_amount;
        total_discount_price += pom.total_discount_price;
        total_tax += pom.total_tax;
    });
   
    return {
        total_cost_price : total_cost_price,
        total_list_price : total_list_price,
        total_discount_amount : total_discount_amount,
        total_discount_price : total_discount_price,
        total_discount: total_discount_price,
        total_tax : total_tax,
        //total: total_discount_price + self.parseNumber(data.shipping) + total_tax,
        total: total_discount_price,
    };
}

function calcGroupSums(data, sAction, dph){
    var total_cost_price = 0;
    var total_list_price = 0;
    var total_discount_amount = 0;
    var total_discount_price = 0;
    var total_tax = 0;
    
    data.lines.forEach(function(prod){
        if(prod.opce_c){
            return;
        }
        let quant = parseFloat(prod.quantity) || 0;
        let line_discout_price = quant*sAction.parseNumber(prod.discount_price)*sAction.parseNumber(prod.pack_size);
        total_discount_price += line_discout_price;

    });


    return {
        total_cost_price : total_cost_price,
        total_list_price : total_list_price,
        total_discount_amount : total_discount_amount,
        total_discount_price : total_discount_price,
        total_tax : total_tax,
    };
}